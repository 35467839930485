.youtube-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.youtube-header {
  margin-bottom: 20px;
}

.header-image {
  width: 100%;
  height: auto; /* Mantiene la proporción de la imagen */
  max-width: 400px; /* Ajusta el tamaño máximo según sea necesario */
  border-radius: 10px; /* Ajusta si es necesario */
}

.youtube-player-container {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1350px;
}

/* Media queries para responsividad */
@media (max-width: 1200px) {
  .youtube-player-container {
    max-width: 80%;
  }
}

@media (max-width: 992px) {
  .youtube-player-container {
    max-width: 70%;
  }

  .react-player {
    height: 400px;
  }
}

@media (max-width: 768px) {
  .youtube-player-container {
    max-width: 90%;
  }

  .react-player {
    height: 300px;
  }
}

@media (max-width: 480px) {
  .react-player {
    height: 200px;
  }
}
