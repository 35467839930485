@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');

/* Estilos para la sección del pie de página */
.footer-section {
  background-color: #ffffff; /* Fondo blanco */
  padding: 0; /* Elimina el padding */
  border-radius: 0; /* Elimina los bordes redondeados */
  font-family: 'Nanum Gothic', sans-serif; /* Fuente Nanum Gothic por defecto */
}

/* Estilos específicos para las columnas de la sección del pie de página */
.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Ajusta en pantallas más pequeñas */
}

.footer-column {
  flex: 1;
  min-height: 150px; /* Altura mínima para las columnas */
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

/* Títulos en negrita y mayúsculas */
.footer-column h2 {
  font-family: 'Lilita One', sans-serif; /* Fuente Lilita One para títulos */
  font-size: 1.5em;
  margin-bottom: 10px;
  text-transform: uppercase; /* Mayúsculas */
  font-weight: bold; /* Negrita */
}

/* Texto regular o light */
.footer-column p, .footer-column ul {
  margin: 0;
  padding: 5px 0;
  font-family: 'Nanum Gothic', sans-serif; /* Fuente Nanum Gothic para el texto regular */
  font-weight: normal; /* Texto regular o light */
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column li {
  margin-bottom: 5px;
  font-family: 'Nanum Gothic', sans-serif; /* Fuente Nanum Gothic para los ítems de la lista */
  font-weight: normal; /* Texto regular o light */
}

/* Estilos para los enlaces en la lista de servicios */
.footer-column li a {
  text-decoration: none;
  color: #000; /* Color por defecto */
  cursor: pointer; /* Cambia el cursor a manita en lugar de cursor de texto */
  transition: color 0.3s ease; /* Añade una transición suave para el cambio de color */
}

.footer-column li a:hover {
  color: #007bff; /* Color de texto en hover */
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-top: 1px solid #ddd; /* Línea de separación si lo deseas */
  text-align: center; /* Centra el contenido de esta fila */
}

.footer-logo {
  flex: 1;
  display: flex;
  justify-content: center; /* Centra el logo en su columna */
}

.footer-logo img {
  max-width: 150px; /* Ajusta el tamaño del logo */
}

.footer-center {
  flex: 2;
}

.footer-center p {
  margin: 0;
  font-size: 0.9em;
  color: #333;
  font-family: 'Nanum Gothic', sans-serif; /* Fuente Nanum Gothic para el texto del centro */
  font-weight: normal; /* Texto regular o light */
}

.footer-social {
  flex: 1;
  display: flex;
  justify-content: center; /* Centra los iconos de redes sociales */
  align-items: center;
  gap: 10px; /* Espacio entre los iconos */
}

.footer-social a {
  display: flex; /* Usa flex para centrar el icono dentro del enlace */
  align-items: center;
  justify-content: center;
  width: 40px; /* Tamaño del contenedor del icono */
  height: 40px;
  background-color: #f0f0f0; /* Color de fondo opcional para visibilidad */
  border-radius: 50%; /* Opcional: bordes redondeados para los iconos */
}

.footer-social a img {
  width: 24px; /* Tamaño de los iconos */
  height: 24px;
}

.contact-link {
  text-decoration: none; /* Elimina el subrayado */
  color: #000; /* Color del texto por defecto */
  font-weight: normal; /* Peso normal del texto */
  display: flex; /* Display flex para alinear el icono y el texto */
  align-items: center; /* Alinea verticalmente el icono y el texto */
  gap: 10px; /* Espacio entre el icono y el texto */
  transition: color 0.3s ease; /* Transición suave para el cambio de color */
  justify-content: center; /* Centra horizontalmente el enlace dentro del contenedor */
}

/* Efecto hover en los enlaces de contacto */
.contact-link:hover {
  color: #007bff; /* Cambia el color en hover */
}

/* Tamaño de los íconos */
.contact-icon {
  width: 24px; /* Ajusta el tamaño del icono */
  height: 24px;
}

/* Media Queries para adaptabilidad */
@media (max-width: 1200px) {
  .footer-content {
    flex-direction: row;
    justify-content: space-around;
  }

  .footer-column {
    margin-bottom: 20px;
    text-align: center;
    flex: 1 1 30%; /* Ajusta el ancho en pantallas medianas */
  }

  .footer-logo img {
    max-width: 120px; /* Ajusta el tamaño del logo en pantallas medianas */
  }
}

@media (max-width: 992px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    margin-bottom: 20px;
    text-align: center;
    flex: 1 1 100%; /* Ocupa el 100% del ancho en pantallas pequeñas */
  }

  .footer-logo img {
    max-width: 100px; /* Ajusta el tamaño del logo en pantallas pequeñas */
  }
}

@media (max-width: 768px) {
  .footer-bottom {
    flex-direction: column;
    text-align: center;
  }

  .footer-logo {
    margin-bottom: 10px;
  }

  .footer-center {
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .footer-column h2 {
    font-size: 1.2em; /* Ajusta el tamaño del título en pantallas muy pequeñas */
  }

  .footer-center p {
    font-size: 0.8em; /* Ajusta el tamaño del texto del centro en pantallas muy pequeñas */
  }

  .footer-social a {
    width: 36px; /* Tamaño reducido para los iconos en pantallas muy pequeñas */
    height: 36px;
  }

  .footer-social a img {
    width: 20px; /* Tamaño reducido para los iconos en pantallas muy pequeñas */
    height: 20px;
  }
}
