@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.slider-container {
  position: relative;
  width: 100vw; /* Ancho completo de la ventana */
  height: 100vh; /* Altura completa de la ventana */
  overflow: hidden;
}

.carousel {
  height: 100%;
  width: 100%;
  display: flex;
}

.carousel-item {
  flex: 1;
  height: 100%;
  position: relative; /* Necesario para posicionar el texto encima de la imagen */
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajusta la imagen para cubrir el contenedor sin dejar espacios en blanco */
  display: block; /* Elimina espacios blancos debajo de la imagen */
}

/* Añadir una capa de opacidad sobre el carrusel */
.carousel-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); /* Ajustar la opacidad aquí */
  z-index: 5; /* Debajo del texto */
}

.carousel-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 10; /* Asegura que el texto esté por encima de las imágenes y el overlay */
}

/* Controles del carrusel */
.carousel-control-prev,
.carousel-control-next {
  z-index: 15; /* Asegúrate de que el z-index sea mayor que el del overlay y el texto */
}

.small-text {
  color: rgb(255, 255, 255); /* Azul */
  font-family: 'Source Code Pro', sans-serif;
  font-size: 40px; /* Tamaño de texto aumentado */
  margin-bottom: 15px; /* Mayor separación */
}

.large-text {
  color: white;
  font-family: 'Lilita One', cursive;
  font-size: 75px; /* Tamaño de texto aumentado */
  margin-bottom: 25px; /* Mayor separación */
  text-transform: uppercase;
}

.btn-primary {
  background-color: blue;
  border-color: blue;
  font-family: 'Nanum Gothic', sans-serif;
  font-size: 20px; /* Tamaño de texto aumentado */
  padding: 15px 30px; /* Tamaño del botón aumentado */
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: darkblue;
  border-color: darkblue;
}

/* Responsivo para pantallas de hasta 768px (tablets y móviles grandes) */
@media (max-width: 768px) {
  .large-text {
    font-size: 40px; /* Tamaño del texto H1 ajustado en tablets */
  }
  .small-text {
    font-size: 20px; /* Tamaño del texto pequeño ajustado en tablets */
  }
  .btn-primary {
    font-size: 16px; /* Tamaño del texto del botón ajustado en tablets */
    padding: 12px 24px; /* Tamaño del botón ajustado en tablets */
  }
}

/* Responsivo para pantallas de hasta 480px (móviles pequeños) */
@media (max-width: 480px) {
  .large-text {
    font-size: 30px; /* Tamaño del texto H1 ajustado en móviles pequeños */
  }
  .small-text {
    font-size: 18px; /* Tamaño del texto pequeño ajustado en móviles pequeños */
  }
  .btn-primary {
    font-size: 14px; /* Tamaño del texto del botón ajustado en móviles pequeños */
    padding: 10px 20px; /* Tamaño del botón ajustado en móviles pequeños */
  }
}
