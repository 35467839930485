/* Oculta la sección de días */
.flip-clock-countdown {
    --show-days: none;
}

.flip-clock-countdown .flip-clock-countdown-day {
    display: none;
}

/* Estilos generales para el contenedor del reloj */
.flip-clock-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 10px; /* Ajusta el espacio alrededor del contenedor */
}

/* Responsivo para pantallas de hasta 768px (tablets y móviles grandes) */
@media (max-width: 768px) {
    .flip-clock {
        --fcc-digit-font-size: 30px; /* Ajusta el tamaño de los dígitos */
        --fcc-label-font-size: 14px; /* Ajusta el tamaño de las etiquetas */
        --fcc-digit-block-width: 30px; /* Reduce el ancho del bloque de dígitos */
        --fcc-digit-block-height: 50px; /* Reduce la altura del bloque de dígitos */
    }
}

/* Responsivo para pantallas de hasta 480px (móviles pequeños) */
@media (max-width: 480px) {
    .flip-clock {
        --fcc-digit-font-size: 20px; /* Ajusta el tamaño de los dígitos */
        --fcc-label-font-size: 12px; /* Ajusta el tamaño de las etiquetas */
        --fcc-digit-block-width: 25px; /* Reduce aún más el ancho del bloque de dígitos */
        --fcc-digit-block-height: 40px; /* Reduce aún más la altura del bloque de dígitos */
    }
}
