@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');

.navbar {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, opacity 0.3s;
  z-index: 1000;
  display: flex;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;
  font-family: 'Source Code Pro', sans-serif;
}

.navbar.scrolled {
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0.9;
}

.navbar.scrolled .navbar-links a {
  color: white;
}

.navbar.scrolled .navbar-toggle-icon,
.navbar.scrolled .navbar-toggle-icon::before,
.navbar.scrolled .navbar-toggle-icon::after {
  background-color: white; 
}

.navbar-logo {
  flex: 1;
}

.logo {
  height: 80px;
}

.navbar-links {
  display: none; 
  flex-direction: column;
  position: absolute;
  top: 100%; 
  right: 0; 
  width: 100%; 
  background-color: white; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  box-sizing: border-box;
  transition: background-color 0.3s ease;
  
}

.navbar-links.active {
  display: flex;
}

.navbar.scrolled .navbar-links {
  background-color: rgba(0, 0, 0, 0.9); 
}

.navbar-links ul {
  display: flex;
  flex-direction: column; 
  margin: 0;
  padding: 0;
  width: 100%;
}

.navbar-links li {
  margin: 5px 0; 
  text-align: center; 
  width: 100%;
}

.navbar-links a,
.navbar-links .scroll-link {
  text-decoration: none;
  color: black;
  font-weight: bold;
  transition: transform 0.3s, color 0.3s;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  font-family: 'Source Code Pro', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.navbar.scrolled .navbar-links a,
.navbar.scrolled .navbar-links .scroll-link {
  color: white; 
}

.navbar-links a:hover,
.navbar-links .scroll-link:hover {
  transform: translateY(-3px);
  color: #007bff;
}

/* Menú tipo burger */
.navbar-toggle {
  display: block;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin-left: auto;
  z-index: 1001;
}

.navbar-toggle-icon {
  width: 30px;
  height: 3px;
  background-color: black;
  position: relative;
  display: block;
  transition: background-color 0.8s ease-in-out;
}

.navbar-toggle-icon::before,
.navbar-toggle-icon::after {
  content: '';
  width: 30px;
  height: 3px;
  background-color: black;
  position: absolute;
  left: 0;
  transition: transform 0.8s ease-in-out, background-color 0.3s ease-in-out;
}

.navbar-toggle-icon::before {
  top: -8px;
}

.navbar-toggle-icon::after {
  top: 8px;
}

/* Media queries */
/* Las opciones del menú burger se mantendrán en cualquier tamaño de pantalla */
@media (min-width: 992px) {
  .navbar-links {
    display: none;
  }

  .navbar-links.active {
    display: flex;
  }
}

@media (max-width: 991px) {
  .navbar-links {
    display: none;
  }

  .navbar-links.active {
    display: flex;
  }
}
