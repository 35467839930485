@import url('https://fonts.googleapis.com/css2?family=Style+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');

.staff-container {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
}

.staff-background {
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    overflow: hidden;
}

.staff-background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: 50px;
    background: url('/public/media/staff_img/staffall.jpg') no-repeat center center;
    background-size: cover;
    z-index: -2;
}

.staff-background::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: -1;
}

.staff-overlay {
    position: relative;
    padding: 20px;
    text-align: center;
    color: white;
    z-index: 1;
}

.staff-title {
    font-family: 'Lilita One'; 
    font-size: 4.5rem;
    margin: 0;
    opacity: 0;
    animation: fadeInUp 1s 0.5s forwards;
    color: #ffffff;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 1.2;
}

.staff-subtitle {
    font-family: 'Source Code Pro', sans-serif;
    font-size: 3.5rem;
    margin-top: 0; 
    color: #ffe600;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    opacity: 0;
    animation: fadeInUp 1s 0.8s forwards;
    letter-spacing: 1.5px;
    line-height: 1.5;
    text-align: center;
    background: rgba(0, 0, 0, 0.6); 
    padding: 10px; 
}

.staff-section {
    margin: 20px;
}

.staff-section-title {
    font-family: 'Lilita One', sans-serif;
    font-size: 2rem;
    text-align: center;
    margin: 20px 0;
    opacity: 0;
    animation: fadeInUp 1s 1s forwards;
}

.staff-cards {
    display: flex;
    justify-content: center; 
    flex-wrap: wrap; 
    margin: 20px;
}

.staff-row {
    display: flex;
    justify-content: center; 
    flex-wrap: wrap;
    margin: 10px; 
}

.staff-card {
    position: relative;
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    margin: 10px;             
    overflow: hidden;         
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 200px;             
    min-height: 300px;        
    flex-direction: column;    
    opacity: 1;
    animation: fadeInUp 1s 1.2s forwards;
}

.staff-card img {
    width: 100%;
    height: auto;
}

.staff-card-info {
    padding: 10px;
    text-align: center;
}

.staff-card-name,
.staff-card-surname,
.staff-card-role {
    font-family: 'Source Code Pro', sans-serif;
    font-weight: 400;
    text-align: center;
}

.staff-card-role {
    font-weight: 900;
    padding: 10px;
}

.staff-card::after {
    content: '';
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px; 
    height: 40px;
    background: url('/public/media/click.webp') no-repeat center center;
    background-size: contain;
    pointer-events: none;
    z-index: 10;  
}

.staff-card:hover {
    transform: translateY(-10px) scale(1.05);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.staff-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    z-index: 1000; 
}

.staff-modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 90%;
    max-height: 90%;
    width: 600px;
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box; 
}

/* Estilo para el título del modal */
.staff-modal-title {
    font-family: 'Lilita One', sans-serif; 
    font-size: 1.5rem;
    margin-bottom: 15px;
}

/* Estilo para el contenido del modal */
.staff-modal-content-text {
    font-family: 'Nanum Gothic', sans-serif; 
    font-size: 1rem;
    line-height: 1.5;
    text-align: left;
}

/* Estilo para la imagen del modal */
.staff-modal-img {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin-bottom: 20px;
}

/* Estilo para el botón de cerrar el modal */
.staff-modal-close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 1.5rem;
    cursor: pointer;
}

/* Animaciones */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Estilos responsivos */
@media (max-width: 1024px) {
    .staff-title {
        font-size: 3.5rem; 
    }

    .staff-subtitle {
        font-size: 2.5rem; 
        padding: 8px; 
    }
}

@media (max-width: 768px) {
    .staff-card {
        width: 200px; /* Mantener el tamaño de la tarjeta constante */
    }
    
    .staff-title {
        font-size: 2.5rem; 
    }

    .staff-subtitle {
        font-size: 2rem; 
        padding: 6px; 
    }
}

@media (max-width: 480px) {
    .staff-card {
        width: 200px; /* Mantener el tamaño de la tarjeta constante */
    }
    
    .staff-title {
        font-size: 2rem; 
    }

    .staff-subtitle {
        font-size: 1.5rem; 
        padding: 4px; 
    }
}
