@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

body {
    padding-top: 60px;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Nanum Gothic', sans-serif;
}

.quiz-container {
    width: 90%;
    max-width: 600px;
    margin: 150px auto 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    min-height: 400px;
    transition: background-color 0.3s;
}

.quiz-message-container {
    margin-bottom: 20px; /* Ajusta el espacio debajo del mensaje según sea necesario */
}

.quiz-message {
    font-family: 'Lilita One', sans-serif;
    color: #000000; /* Color negro */
    font-size: 18px; /* Ajusta el tamaño de fuente según sea necesario */
    font-weight: bold; /* Opcional, ajusta el peso de la fuente si lo deseas */
    text-align: center; /* Centra el mensaje */
    padding: 10px;
    border-radius: 5px; /* Opcional, para bordes redondeados */
}

.quiz-container.correct {
    background-color: #35fd03;
}

.quiz-container.incorrect {
    background-color: #ff4c4c;
}

.quiz-question {
    font-family: 'Lilita One', cursive;
    font-size: 1.5rem;
    margin: 20px 0;
}

.quiz-feedback {
    font-size: 1.5rem;
    margin: 10px 0;
    font-weight: bold;
}

.quiz-image-container {
    width: 100%;
    height: 200px;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #f0f0f0;
    border-radius: 10px;
}

.quiz-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.quiz-options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin: 20px 0;
    padding: 0;
    list-style: none;
}

.promotion-image {
    width: 100px; /* Ajusta según el tamaño deseado */
    height: auto; /* Mantiene la relación de aspecto */
    vertical-align: middle; /* Alinea verticalmente con el texto */
    margin: 0 10px; /* Espaciado alrededor de la imagen */
    animation: breathing 2s infinite; /* Aplicar la animación */
}

/* Definición de la animación breathing */
@keyframes breathing {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.05); /* Aumenta el tamaño ligeramente */
        opacity: 0.8; /* Reduce la opacidad */
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.quiz-option {
    padding: 10px;
    font-size: 1.1rem;
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: box-shadow 0.3s, transform 0.3s;
    font-family: 'Lilita One';
}

.quiz-option:nth-child(1) {
    background-color: #ff4c4c;
}

.quiz-option:nth-child(2) {
    background-color: #4c8cff;
}

.quiz-option:nth-child(3) {
    background-color: #f4c542;
}

.quiz-option:nth-child(4) {
    background-color: #07c20d;
}

.quiz-option.selected {
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.5);
    transform: scale(1.05);
}

.quiz-option.correct {
    background-color: #00f808;
    color: white;
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.5);
    transform: scale(1.05);
}

.quiz-option.incorrect {
    background-color: #4c8cff;
    color: white;
}

.quiz-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.quiz-submit,
.quiz-next {
    padding: 10px 20px;
    font-size: 1.1rem;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.3s;
    font-family: 'Lilita One', cursive;
}

.quiz-submit:hover,
.quiz-next:hover {
    background-color: #0056b3;
}

.quiz-contact-button {
    background-color: #25D366;
    color: rgb(0, 0, 0);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    text-align: center;
    transition: background-color 0.3s;
}

.quiz-contact-button img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.quiz-contact-button:hover {
    background-color: #1ebd63;
}

.quiz-retry-button {
    background-color: #fff;
    color: #007bff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    transition: background-color 0.3s, color 0.3s;
}

.quiz-retry-button:hover {
    background-color: #007bff;
    color: #fff;
}

.quiz-results {
    margin-top: 100px;
    text-align: center;
    color: white;
}

.quiz-results-header h2 {
    font-family: 'Lilita One', cursive;
    color: rgb(255, 251, 0); /* Amarillo */
    font-size: 4rem;
    -webkit-text-stroke: 2px #000;
}

.quiz-results-score p {
    font-family: 'Lilita One';
    font-size: 2rem;
}

.quiz-score-text {
    font-size: 2.5rem;
    font-weight: bold;
    color: rgb(255, 255, 255);
    -webkit-text-stroke: 1.5px #000;
}

.quiz-score {
    font-size: 2rem;
    color: red;
}

.quiz-total {
    font-size: 2rem;
    color: red;
}

.quiz-message {
    margin-top: 10px;
    font-size: 1rem;
    color: white;
    -webkit-text-stroke: 1.5px #000;
}

.slash, .exclamation {
    color: red; 
    font-size: 2rem; 
}

.quiz-results-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.quiz-results-promotion .promotion-text {
    font-family: 'Lilita One';
    color: rgb(115, 255, 0);
    font-size: 2.5rem;
    -webkit-text-stroke: 2px #000;
}

.quiz-results-contact .quiz-contact-button {
    background-color: #25D366;
    color: rgb(0, 0, 0);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    text-align: center;
}

.quiz-results-retry .quiz-retry-button {
    background-color: #fff;
    color: #007bff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

/* Responsivo para dispositivos móviles */
@media (max-width: 768px) {
    .quiz-container {
        width: 95%;
        margin: 100px auto 20px;
        padding: 15px;
    }

    .quiz-question {
        font-size: 1.2rem;
    }

    .quiz-feedback {
        font-size: 1.2rem;
    }

    .quiz-image-container {
        height: 150px;
    }

    .quiz-options {
        grid-template-columns: 1fr;
    }

    .quiz-option {
        font-size: 1rem;
        padding: 8px;
    }

    .quiz-submit,
    .quiz-next {
        font-size: 1rem;
        padding: 8px 16px;
    }

    .quiz-contact-button {
        font-size: 1rem;
    }

    .quiz-retry-button {
        font-size: 1rem;
    }

    .quiz-results-header h2 {
        font-size: 2.5rem;
    }

    .quiz-results-score p {
        font-size: 1.2rem;
    }

    .quiz-results-promotion .promotion-text {
        font-size: 1.8rem;
    }
}

/* Responsivo para dispositivos extra pequeños */
@media (max-width: 480px) {
    .quiz-container {
        margin: 100px auto 20px;
        padding: 10px;
    }

    .quiz-question {
        font-size: 1rem;
    }

    .quiz-feedback {
        font-size: 1rem;
    }

    .quiz-image-container {
        height: 120px;
    }

    .quiz-options {
        grid-template-columns: 1fr;
    }

    .quiz-option {
        font-size: 0.9rem;
        padding: 6px;
    }

    .quiz-submit,
    .quiz-next {
        font-size: 0.9rem;
        padding: 6px 12px;
    }

    .quiz-contact-button {
        font-size: 0.9rem;
    }

    .quiz-retry-button {
        font-size: 0.9rem;
    }

    .quiz-results-header h2 {
        font-size: 2rem;
    }

    .quiz-results-score p {
        font-size: 1rem;
    }

    .quiz-results-promotion .promotion-text {
        font-size: 1.5rem;
    }
}
