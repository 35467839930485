/* src/styles/ScrollIndicator.css */
.scroll-indicator {
    position: fixed;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000; /* Asegura que esté encima de todo el contenido */
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer; /* Cambia el cursor a una mano al pasar sobre el indicador */
  }
  
  .scroll-indicator-icon {
    color: #fff;
    font-size: 1.5rem;
    opacity: 0.8;
    animation: bounce 1s ease infinite;
  }
  
  .scroll-indicator-icon + .scroll-indicator-icon {
    margin-top: 0.5rem; /* Espacio entre los dos íconos */
  }
  
  @keyframes bounce {
    50% {
      transform: translateY(-50%);
    }
  }
  