@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap'); /* Importar fuente Lilita One */
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap'); /* Importar fuente Nanum Gothic */

.info-section {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column; /* Asegura que los elementos en la columna se apilen verticalmente */
  align-items: center; /* Centra horizontalmente */
  justify-content: center; /* Centra verticalmente */
  text-align: center; /* Centra el texto dentro del contenedor */
  transition: transform 1s ease-out, opacity 1s ease-out; /* Transiciones para animaciones */
}

.horarios {
  background-color: #4b60d5; /* Fondo azul */
  animation: slide-in-left 1s ease-out;
  font-family: 'Lilita One'; /* Tipografía Lilita One */
  max-width: 600px; /* Ajusta el ancho máximo para mejor apariencia */
  width: 100%; /* Asegura que ocupe el 100% del contenedor */
}

.horarios h2 {
  font-size: 3em; /* Tamaño grande para el título */
  text-transform: uppercase; /* Texto en mayúsculas */
  margin-bottom: 20px;
  color: rgb(255, 208, 0); /* Color blanco */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6); /* Sombra del texto */
}

.horarios ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.horarios li {
  font-size: 2em;
  margin-bottom: 10px;
  padding: 10px;
  background: none; /* Sin fondo */
  font-family: 'Lilita One', sans-serif; /* Tipografía Nanum Gothic */
  color: rgb(255, 255, 255); /* Color blanco */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); /* Sombra del texto */
  font-size: 1.5em;
}

.horarios li.closed {
  font-size: 2.5em; /* Aumenta el tamaño de la fuente para Domingo */
  animation: blink-red 2s step-start 0s infinite; /* Solo el último ítem parpadea */
  font-family: 'Lilita One', sans-serif; /* Mantiene la tipografía Lilita One para Domingo */
}

.puntos {
  margin: -10px 0 25px 0;
}

.puntos span {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #ffffff; /* Color blanco para los puntos */
  border-radius: 50%; /* Forma circular */
  margin: 0 5px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.mapa {
  animation: slide-in-right 1s ease-out;
  width: 100%; /* Asegura que ocupe el 100% del contenedor */
}

/* Ajustar el contenedor del mapa */
.map-container {
  width: 100%;
  height: 400px; /* Ajusta la altura según sea necesario */
  overflow: hidden; /* Evita que el mapa se desborde */
}

.contact-icon {
  width: 20px; /* Ajusta el ancho del ícono */
  height: 20px; /* Ajusta la altura del ícono */
  margin-right: 8px; /* Añade un margen a la derecha para separar el ícono del texto */
  vertical-align: middle; /* Alinea verticalmente el ícono con el texto */
}

/* Animaciones de entrada */
@keyframes slide-in-left {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Animación de parpadeo en rojo */
@keyframes blink-red {
  0%, 50%, 100% {
    color: rgb(255, 255, 255); /* Color blanco */
  }
  25%, 75% {
    color: red; /* Color rojo */
  }
}

/* Media Queries para adaptabilidad */
@media (max-width: 768px) {
  .info-section {
    flex-direction: column;
  }

  .column {
    margin: 10px 0;
  }
}
