@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');

.certifications-section {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.header-image {
    width: 100%;
    height: auto;
    max-width: 400px;
    border-radius: 10px;
}

.certifications-header {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
}

.certifications-title {
    font-family: 'Lilita One', cursive;
    font-size: 2em;
    color: #000000;
    padding: 40px 60px;
    background-color: transparent;
    display: inline-block;
    line-height: 1.4;
    position: relative;
    z-index: 1;
    text-align: center;
    border-radius: 10px;
}

.certifications-title::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 10px;
    opacity: 1;
}

.certifications-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.certification-card {
    width: 100%;
    height: 300px;
    perspective: 1000px;
    overflow: hidden;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateZ(0); /* Aceleración por hardware */
}

.card-inner {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.8s ease;
    backface-visibility: hidden; /* Solución para Firefox */
    -webkit-backface-visibility: hidden; /* Safari */
    -moz-backface-visibility: hidden; /* Firefox */
}

.certification-card:hover .card-inner {
    transform: rotateY(180deg);
}

.card-front,
.card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden; /* Evita que se vea el lado oculto */
    -webkit-backface-visibility: hidden; /* Safari */
    -moz-backface-visibility: hidden; /* Firefox */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.card-front img,
.card-back img {
    max-width: calc(100% - 20px);
    max-height: calc(100% - 20px);
    object-fit: cover;
    border-radius: 10px;
    padding: 10px;
}

.card-back {
    transform: rotateY(180deg);
    z-index: 0; /* Asegura que la cara trasera quede detrás */
}

/* Responsividad */
@media (max-width: 992px) {
    .certifications-cards {
        grid-template-columns: repeat(2, 1fr);
    }

    .certifications-title {
        font-size: 1.75em;
        padding: 30px 50px;
    }
}

@media (max-width: 768px) {
    .certifications-cards {
        grid-template-columns: 1fr;
    }

    .certifications-title {
        font-size: 1.5em;
        padding: 20px 40px;
    }
}

@media (max-width: 480px) {
    .certifications-title {
        font-size: 1.25em;
        padding: 15px 30px;
    }
}
