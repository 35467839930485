body {
  margin: 0;
  padding: 0;
  background: url('/public/media/Background.png') no-repeat center center fixed;
  background-size: cover; /* Ajusta la imagen para cubrir todo el fondo */
  overflow-x: hidden; /* Evita el scroll horizontal */
  width: 100vw; /* Asegura que el cuerpo del documento ocupe todo el ancho de la ventana */
  height: 100vh; /* Asegura que el cuerpo del documento ocupe todo el alto de la ventana */
  box-sizing: border-box; /* Incluye padding y border en el cálculo del ancho y alto del elemento */
}

/* Asegura que el tamaño del fondo se ajuste correctamente en pantallas más pequeñas */
@media (max-width: 768px) {
  body {
    background-position: center center;
    background-size: cover; /* Asegura que la imagen de fondo cubra todo el área visible */
  }
}