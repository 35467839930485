@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.courses-section {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
  margin-top: 80px;
  align-items: center;
}

.header-image {
  width: 100%;
  height: auto;
  max-width: 400px;
  border-radius: 10px;
}

.course-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  align-items: center;
  width: 100%;
  transition: transform 0.3s ease;
}

.course-info h2.colores-effect {
  font-weight: bold;
  background: linear-gradient(45deg, #FF6F61, #FFCC6C, #6B5B95, #88B04B, #F7CAC9, #92A8D1, #F5AB99);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  display: inline;
}

.course-info p {
  text-align: justify;
}

.image-container {
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-container::after {
  content: '';
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  background: url('/public/media/click.webp') no-repeat center center;
  background-size: contain;
  z-index: 10; /* Asegúrate de que esté por encima de otros elementos */
  pointer-events: none; /* Asegura que no interfiera con otros elementos interactivos */
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  /* Añadir el GIF en la esquina superior derecha */
}

.overlay::before {
  content: url('/public/media/click.webp');
  position: absolute;
  top: 10px; 
  right: 10px; 
  width: 40px; 
  height: auto; 
  pointer-events: none; /* Evita que el GIF interfiera con los clics en otros elementos */
  z-index: 1; /* Asegúrate de que esté por encima de otros elementos dentro del overlay */
}

.image-container:hover .overlay {
  opacity: 1;
}

.overlay-link {
  color: #fff;
  font-size: 2rem;
  text-decoration: none;
}

.overlay-text {
  font-family: 'Lilita One', cursive;
  text-align: center;
  padding: 10px 20px;
  border-radius: 5px;
}

.course-info {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.course-info h2 {
  margin: 0;
  font-size: 2.5rem;
  color: #333;
  font-family: 'Lilita One', cursive;
}

.course-info h3 {
  margin: 15px 0;
  font-size: 1.5rem;
  color: #666;
  font-family: 'Lilita One', cursive;
  text-decoration: underline;
}

.course-info p {
  font-size: 1.2rem;
  color: #444444;
  font-family: 'Nanum Gothic', sans-serif;
  margin-bottom: 20px;
}

.more-info-link {
  color: #007bff;
  cursor: pointer;
  font-size: 1.4rem;
  display: inline-block;
  margin-top: 20px;
  position: relative;
  z-index: 1;
  transition: color 0.3s ease, transform 0.3s ease;
  text-decoration: none;
}

.more-info-link:hover {
  color: #0056b3;
  transform: scale(1.1);
}

/* Modal Styles */
.curso-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  z-index: 1000;
}

.curso-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Nanum Gothic', sans-serif;
}

.curso-modal-content h2 {
  margin-top: 0;
  font-size: 1.8rem;
  color: #333;
  font-family: 'Lilita One', cursive;
}

.curso-modal-content p {
  font-size: 1rem;
  color: #777;
  margin: 10px 0;
  text-align: center;
}

.curso-modal-content button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.curso-modal-content button:hover {
  background-color: #0056b3;
}

@media (min-width: 768px) {
  .course-card {
    flex-direction: row;
    align-items: stretch;
    width: 100%;
  }

  .image-container, .course-info {
    width: 50%; 
    height: 100%; 
  }
}

/* Responsive Styles */

/* Estilos para pantallas medianas y grandes (a partir de 768px) */
@media (min-width: 768px) {
  .course-card {
    flex-direction: row;
  }

  .image-container, .course-info {
    width: 50%;
    height: auto; /* Permite que el contenido determine la altura */
  }
}

/* Estilos para pantallas grandes (a partir de 1024px) */
@media (min-width: 1024px) {
  .course-card {
    flex-direction: row;
    align-items: stretch;
  }

  .image-container {
    width: 50%;
    height: auto;
  }

  .course-info {
    width: 50%;
    padding: 30px;
    text-align: left; /* Texto alineado a la izquierda en pantallas grandes */
  }
}

/* Estilos para pantallas pequeñas y medianas (ancho menor a 1024px) */
@media (max-width: 1024px) {
  .course-card {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    min-height: 0; /* Elimina la altura mínima para evitar espacios */
  }

  .image-container {
    width: 100%;
    height: auto;
  }

  .course-info {
    width: 100%;
    padding: 20px;
  }
}