/* src/components/WhatsAppButton.css */
.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 80px; /* Tamaño del botón ajustado */
    height: 80px; /* Tamaño del botón ajustado */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Asegura que el botón esté sobre otros elementos */
    animation: move 1s infinite alternate; /* Animación de movimiento más rápida */
    transition: transform 0.3s ease; /* Transición para animaciones */
    background: transparent; /* Asegura que no haya fondo visible */
    border: none; /* Asegura que no haya borde */
}

/* Asegúrate de que la imagen del icono se ajuste al botón */
.whatsapp-icon {
    width: 100%;
    height: auto; /* Mantiene la proporción de la imagen */
}

/* Animación de movimiento */
@keyframes move {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-10px);
    }
}

/* Efecto de inflado al pasar el cursor */
.whatsapp-button:hover {
    transform: scale(1.2); /* Aumenta el tamaño del botón */
}
