.popup-button {
    background-color: #004aad;
    color: #ffffff;
    text-transform: none; /* Evita las mayúsculas */
    font-family: 'Montserrat', sans-serif;
    font-weight: 279; /* Usar el mismo peso que en las descripciones */
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 2000;
  }
  
  .popup-button:hover {
    background-color: #003a80; /* Color más oscuro al hacer hover */
  }
  