@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');

.alianzas-container {
    padding: 100px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    box-sizing: border-box;
    background-color: #bdeaff;
}

.header-image {
    width: 100%;
    height: auto;
    max-width: 400px;
    border-radius: 10px;
}

.alianzas-title {
    font-family: 'Lilita One', sans-serif;
    font-size: 3rem;
    text-align: center;
    margin-bottom: 40px;
    padding: 40px 30px;
    background: url('/public/media/backgrounds/paint-spray.png') no-repeat center center;
    background-size: cover;
    border-radius: 10px;
    color: #000;
    background-color: transparent;
    display: inline-block;
    max-width: 100%;
    box-sizing: border-box;
}

.alianzas-grid {
    display: flex;
    flex-wrap: wrap; /* Permite que las tarjetas hagan wrap en filas */
    justify-content: center; /* Centra las tarjetas dentro del contenedor */
    gap: 20px; /* Espacio entre las tarjetas */
    width: 100%;
    max-width: 1200px; /* Limita el ancho máximo del contenedor */
    box-sizing: border-box;
    grid-template-columns: repeat(3, 1fr);
}

.alianza-card {
    background: white;
    border-radius: 10px; /* Puedes mantener el borde redondeado si lo deseas */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); /* Aumenta la sombra para un efecto más destacado */
    padding: 40px; /* Aumenta el padding para hacer la card más grande */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 200px; /* Define el ancho de la card */
    height: 200px; /* Define la altura de la card */
    margin: 10px; /* Ajusta el margen para dar espacio entre las cards */
}


.fade-out {
    opacity: 0;
    transform: translateY(30px);
}

.alianza-image {
    width: 100%;
    height: 100%; /* Ajusta la altura para que ocupe todo el contenedor */
    object-fit: contain; /* Ajusta la imagen para que se vea completa sin recortarla */
    border-radius: 0;
    display: block; /* Asegura que la imagen se comporte como un bloque */ 
    margin: 0 auto; /* Centra la imagen dentro del contenedor */
}

.alianza-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    margin-top: 15px;
    text-align: center;
    box-sizing: border-box;
}

.alianza-info h3 {
    font-family: 'Lilita One', sans-serif;
    font-size: 2rem; /* Aumenta el tamaño del título */
    margin: 10px 0;
}

.alianza-info p {
    font-family: 'Nanum Gothic', sans-serif;
    font-size: 1.2rem; /* Aumenta el tamaño del texto */
    margin-bottom: 20px;
}

@media (max-width: 1024px) {
    .alianzas-title {
        font-size: 2.5rem;
    }
    .alianza-info h3 {
        font-size: 1.8rem;
    }
    .alianza-info p {
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .alianzas-title {
        font-size: 2rem;
    }
    .alianza-info h3 {
        font-size: 1.6rem;
    }
    .alianza-info p {
        font-size: 0.95rem;
    }
}

@media (max-width: 480px) {
    .alianzas-container {
        padding: 80px 10px 10px;
    }
    .alianzas-title {
        font-size: 1.5rem;
    }
    .alianza-info h3 {
        font-size: 1.4rem; /* Aumenta el tamaño del título en dispositivos pequeños */
    }
    .alianza-info p {
        font-size: 0.85rem;
    }
}
