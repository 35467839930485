@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');

.opiniones-section {
  width: 100vw;
  padding: 20px;
  box-sizing: border-box;
  background-color: #bdeaff;
  position: relative;
  overflow: hidden;
  margin: 0;
}

.header-container {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.header-image {
  width: 100%;
  height: auto;
  /* Mantiene la proporción de la imagen */
  max-width: 400px;
  /* Ajusta el tamaño máximo según sea necesario */
  border-radius: 10px;
  /* Ajusta si es necesario */
}

.header-text {
  font-family: 'Lilita One';
  font-size: 2em;
  display: inline-block;
  padding: 20px;
  border-radius: 10px;
  color: #000;
  position: relative;
  overflow: hidden;
  box-shadow: none;
}

.header-text::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  z-index: -1;
}

.opiniones-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.opinion {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: opacity 0.6s ease-out;
}

.student-name {
  font-weight: bold;
  margin: 10px 0;
  font-size: 1.1em;
}

.opinion-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.student-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 15px;
  object-fit: cover;
}

.rating {
  display: flex;
}

.star {
  color: #ffd700;
  font-size: 1.5em;
}

.star.filled {
  color: #ffd700;
}

.star:not(.filled) {
  color: #e0e0e0;
}

.opinion-text {
  font-size: 1em;
}

@keyframes curtain-open {
  0% {
    transform: scaleY(0);
    transform-origin: top;
  }
  100% {
    transform: scaleY(1);
    transform-origin: top;
  }
}

.opiniones-section {
  animation: curtain-open 1s ease-out;
}

@media (max-width: 768px) {
  .opiniones-container {
    grid-template-columns: 1fr;
  }
}
