@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');

.countdown-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Ocupa toda la altura de la ventana */
    background: rgba(0, 0, 0, 0.5); /* Fondo oscuro con opacidad */
    /* Puedes agregar una imagen de fondo aquí si deseas */
    /* background-image: url('ruta-a-tu-imagen.jpg'); */
    background-blur: 5px; /* Opcional, si quieres desenfoque de fondo */
}

.countdown-message {
    font-family: 'Lilita One', sans-serif;
    font-size: 48px; 
    color: #ffffff; 
    -webkit-text-stroke: 4px #000000;
    margin-bottom: 20px; 
}

.countdown-timer {
    font-family: 'Lilita One', sans-serif;
    font-size: 100px; /* Tamaño grande del temporizador */
    color: #ff0000; /* Color rojo para el temporizador */
    font-weight: bold; /* Opcional, ajusta el peso de la fuente si lo deseas */
    text-align: center; /* Centra el mensaje */
    padding: 10px;
    border-radius: 10px; /* Opcional, para bordes redondeados */
    -webkit-text-stroke: 4px #000000; /* Trazo negro para el texto */
    animation: fadeInOut 1s infinite; /* Animación de parpadeo */
}

.countdown-timer.animate {
    animation: pulse 1s infinite; /* Animación de pulso */
}

/* Keyframes para animaciones */
@keyframes fadeInOut {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.2);
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

/* Estilo para el GO! */
.countdown-go {
    font-family: 'Lilita One', sans-serif;
    font-size: 100px; /* Tamaño grande para GO! */
    color: #00ff00; /* Color verde */
    font-weight: bold; /* Opcional, ajusta el peso de la fuente si lo deseas */
    -webkit-text-stroke: 2px #000000; /* Trazo negro para el texto */
    text-align: center; /* Centra el mensaje */
}
