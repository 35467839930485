@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');

.eventos-especiales {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.header-image {
  width: 100%;
  height: auto; /* Mantiene la proporción de la imagen */
  max-width: 400px; /* Ajusta el tamaño máximo según sea necesario */
  border-radius: 10px; /* Ajusta si es necesario */
}

.header-container {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.header-text {
  font-family: 'Lilita One'; /* Tipografía tipo school */
  font-size: 2em;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
}

/* Estilo para las pestañas */
.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Permite que las pestañas se ajusten en pantallas más pequeñas */
}

.tabs button {
  background: #f0f0f0;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  font-size: 1em;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.tabs button.active {
  background: #ffeb3b; /* Color para la pestaña activa */
  font-weight: bold;
}

/* Estilo del contenedor del carrusel */
.carousel-container-festejos {
  width: 100%;
  max-width: 1200px; /* Ajusta el ancho máximo del carrusel */
  margin: 0 auto;
  position: relative; /* Asegura que el contenedor se alinee correctamente */
}

/* Estilo del carrusel con altura fija */
.fixed-carousel-festejos .carousel-item {
  height: 500px; /* Ajusta la altura fija del carrusel */
  position: relative;
}

.fixed-carousel-festejos .carousel-item img {
  width: 100%;
  height: 100%; /* Hace que la imagen ocupe toda la altura del carrusel */
  object-fit: contain; /* Ajusta la imagen para que cubra el contenedor sin deformarse */
}

/* Transiciones para los carousels */
.carousel-wrapper-festejos {
  display: none; /* Oculta todos los carousels por defecto */
}

.carousel-wrapper-festejos.active {
  display: block; /* Muestra solo el carousel activo */
}
/* Estilos específicos para los items del carrusel en la sección de eventos especiales */
.carousel-item-festejos {
  position: relative;
}

.carousel-item-festejos img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block; /* Asegúrate de que no haya espacios blancos debajo de la imagen */
}

/* Eliminar opacidad del carrusel en la sección de eventos especiales */
.carousel-item-festejos::before {
  content: none; /* Elimina la capa de opacidad */
}

/* Animaciones de scroll con AOS */
.aos-init {
  opacity: 0;
  transition: opacity 1s ease-out;
}

.aos-animate {
  opacity: 1;
  transition: opacity 1s ease-out;
}

/* Media Queries para mejorar la adaptabilidad */
@media (max-width: 768px) {
  .tabs button {
    padding: 8px 15px;
    font-size: 0.9em;
    margin: 5px 0; /* Reduce el margen en pantallas más pequeñas */
  }

  .fixed-carousel-festejos .carousel-item {
    height: 400px; /* Ajusta la altura del carrusel en pantallas más pequeñas */
  }

  .header-text {
    font-size: 1.5em; /* Reduce el tamaño de fuente en pantallas más pequeñas */
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .header-text {
    font-size: 1.2em; /* Reduce aún más el tamaño de fuente en pantallas muy pequeñas */
    padding: 6px;
  }

  .fixed-carousel-festejos .carousel-item {
    height: 300px; /* Ajusta la altura del carrusel en pantallas muy pequeñas */
  }
}
