@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');

.job-container {
    padding: 100px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    box-sizing: border-box; /* Asegura que el padding se incluya en el tamaño del contenedor */
}

.header-image {
    width: 100%;
    height: auto;
    /* Mantiene la proporción de la imagen */
    max-width: 400px;
    /* Ajusta el tamaño máximo según sea necesario */
    border-radius: 10px;
    /* Ajusta si es necesario */
  }

.bolsa-title {
    font-family: 'Lilita One', sans-serif;
    font-size: 3rem;
    text-align: center;
    margin-bottom: 40px;
    padding: 40px 30px;
    background: url('/public/media/backgrounds/paint-spray.png') no-repeat center center;
    background-size: cover;
    border-radius: 10px;
    color: #000;
    background-color: transparent;
    display: inline-block;
    max-width: 100%; /* Asegura que no se desborde en pantallas pequeñas */
    box-sizing: border-box; /* Asegura que el padding se incluya en el tamaño del título */
}

.job-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columnas fijas para pantallas grandes */
    gap: 20px;
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box; /* Asegura que los márgenes se incluyan en el tamaño */
}

.job-card {
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    min-height: 350px; /* Mantiene la altura mínima */
    box-sizing: border-box; /* Para que el padding esté incluido en el tamaño del contenedor */
    transition: opacity 0.7s ease, transform 0.7s ease;
    opacity: 1;
    transform: translateY(0);
}

.fade-out {
    opacity: 0;
    transform: translateY(30px); /* Mueve la tarjeta ligeramente hacia abajo */
}

.job-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
}

.job-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    margin-top: 15px;
    text-align: center;
    box-sizing: border-box;
}

.job-info h3 {
    font-family: 'Lilita One', sans-serif;
    font-size: 1.5rem;
    margin: 10px 0;
}

.job-info p {
    font-family: 'Nanum Gothic', sans-serif;
    font-size: 1rem;
    margin-bottom: 20px;
}

.cv-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
    text-decoration: none;
    display: inline-block;
    align-self: center;
}

.cv-button:hover {
    background-color: #0056b3;
}

@media (max-width: 1024px) {
    .job-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 columnas en pantallas medianas */
    }
    .bolsa-title {
        font-size: 2.5rem; /* Ajusta el tamaño del título */
    }
    .job-info h3 {
        font-size: 1.4rem;
    }
    .job-info p {
        font-size: 0.95rem;
    }
}

@media (max-width: 768px) {
    .job-grid {
        grid-template-columns: repeat(2, 1fr); /* 2 columnas en pantallas medianas */
    }
    .bolsa-title {
        font-size: 2rem;
    }
    .job-info h3 {
        font-size: 1.25rem;
    }
    .job-info p {
        font-size: 0.9rem;
    }
    .cv-button {
        font-size: 0.95rem;
    }
}

@media (max-width: 480px) {
    .job-container {
        padding: 80px 10px 10px;
    }
    .job-grid {
        grid-template-columns: 1fr; /* 1 columna en pantallas pequeñas */
    }
    .bolsa-title {
        font-size: 1.5rem;
    }
    .job-info h3 {
        font-size: 1rem;
    }
    .job-info p {
        font-size: 0.85rem;
    }
    .cv-button {
        font-size: 0.9rem;
    }
}
